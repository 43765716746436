<template>
	<div
		v-if="title || image || teaser || link.length"
		:id="$vnode.key"
		class="c-block-list-ctabox-custom"
	>
		<LongReadTarget
			v-if="title && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<div class="flex flex-col">
			<UmbracoImageExt
				v-if="image"
				:widths="[320, 480, 640, 928]"
				:alt="image.altText"
				:source-url="image.cropUrl"
				:source-width="image.width"
				:source-height="image.height"
				:aspect-ratio="863 / 370"
			/>

			<div class="bg-primary py-2xl px-3xl">
				<div class="space-y-lg">
					<BaseH2 v-if="title" v-text="title" />
					<div v-if="teaser" class="text-body" v-text="teaser"></div>
				</div>

				<BaseButton
					v-if="link && link[0]"
					tag="NuxtLinkExt"
					:to="link[0].url"
					:target="link[0].target == null ? '_self' : link[0].target"
					class="block mt-xl max-w-fit"
					dark
					v-text="linktext || link[0].name"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';

export default {
	name: 'BlockListCtaboxCustom',
	components: { UmbracoImageExt, LongReadTarget },
	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		teaser: {
			type: String,
			required: false,
		},
		image: {
			type: Object,
			required: false,
		},
		linktext: {
			type: String,
			required: false,
		},
		link: {
			type: Array,
			required: false,
		},
		includeInToc: {
			type: Boolean,
			required: false,
		},
	},
};
</script>
